import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import * as Routes from 'constants/Routes'

const UnknownUser = lazy(() => import('./components/UnknownUser/UnknownUser'))

export default (
  <Route
    exact
    path={Routes.UNKNOWN_USER}
    component={UnknownUser}
  />
)
