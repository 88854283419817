import React from 'react'
import styles from './SearchInput.scss'
import { Input } from 'components/FormElements/index'
import { SearchIcon, CloseIcon } from 'frontend-react-components'
import isFieldComponentEqual from 'utils/isFieldComponentEqual'
import Spinner from 'components/Spinner/Spinner'

const SearchInput = ({
  form,
  field,
  isSearching,
  onClear,
  ...props
}) => {
  const showClear = field.value !== '' && !isSearching

  const handleClearClick = () => {
    onClear && onClear()

    field.onChange && field.onChange({
      target: {
        value: ''
      }
    })
  }

  return (
    <div className={styles.component}>
      <div className={styles.icon}>
        <SearchIcon />
      </div>
      {isSearching && (
        <div className={styles.loaderWrap}>
          <Spinner
            primary
            className={styles.loader}
          />
        </div>
      )}
      {showClear && (
        <div className={styles.btnClearWrap}>
          <button
            onClick={handleClearClick}
            className={styles.btnClear}
          >
            <CloseIcon />
          </button>
        </div>
      )}
      <Input
        className={styles.input}
        form={form}
        field={field}
        {...props}
      />
    </div>
  )
}

SearchInput.displayName = 'SearchInput'

export default React.memo(SearchInput, isFieldComponentEqual)
