import queryString from 'query-string'
import moment from 'moment'
import { capitalizeFirst } from './strings'
import * as DealType from '../constants/DealType'
import * as DealStatus from '../constants/DealStatus'
import { getProductArtistName } from './data'

export const getDealIdFromLocation = location => {
  const queryParams = queryString.parse(location.search)

  if (queryParams?.dealId) return queryParams?.dealId
  if (!location.pathname) return false

  const tokens = location.pathname.split('/')
  if (tokens.length < 2) return false

  return tokens[tokens.length - 2] === 'deals' ? tokens[tokens.length - 1] : false
}

export const getProductQueryParams = location => {
  const queryParams = queryString.parse(location.search)

  if (!queryParams.productUpc || !queryParams.productSystem) {
    return false
  }

  return {
    upc: queryParams.productUpc,
    system: queryParams.productSystem
  }
}

export const getDealFormNavLink = (route, location) => {
  const queryParams = queryString.parse(location.search)
  return `${route}?dealId=${queryParams.dealId}`
}

export const getDealTypeLabel = dealType => {
  return capitalizeFirst(dealType)
}

export const getDealArtistsLabel = deal => {
  const type = deal?.type

  if (type === DealType.PRODUCT) {
    return getProductArtistName(deal?.subject)
  }

  return deal?.subject?.artist?.name || null
}

export const getExtendedDealTypeLabel = deal => {
  const { subject, system } = deal
  const dealTypeLabel = getDealTypeLabel(deal.type)

  const id = subject?.ids?.find(id => id.system === system)?.localId

  if (!id) {
    return dealTypeLabel
  }

  return `${dealTypeLabel} (ID ${id})`
}

export const getDealDateLabel = (deal, format = 'D MMM YYYY') => {
  if (!deal.start && !deal.end) {
    return null
  }

  let output = ''

  if (deal.start) {
    const mStart = moment.utc(deal.start)
    output += mStart.format(format)
  }

  if (deal.end) {
    const mEnd = moment.utc(deal.end)

    if (deal.start) {
      output += ' / '
    }

    output += mEnd.format(format)
  }

  return output
}

export const getDealOrchardLabelLabel = deal => {
  return deal?.labelAccount?.name
}

export const getDealOrchardSubAccountLabel = deal => {
  return deal?.subAccount?.name
}

export const getDealSonyMaintenanceOwnerLabel = deal => {
  return deal?.maintenanceOwner?.name
}

export const getDealSonyRepertoireOwnerLabel = deal => {
  return deal?.repertoireOwner?.name
}

export const getDealSonyImprintLabel = deal => {
  return deal?.imprint?.name
}

export const getDealSAPProfitCenterLabel = deal => {
  return deal?.sapProfitCenters
    ?.reduce((output, r) => {
      output += `${r?.company?.name}, `
      return output
    }, '')
    ?.slice(0, -2)
}

export const getDealSAPCompanyLabel = deal => {
  return deal?.sapCompanies
    ?.reduce((output, r) => {
      output += `${r?.company?.name}, `
      return output
    }, '')
    ?.slice(0, -2)
}

export const getIsDealDraft = deal => {
  return deal?.status === DealStatus.DRAFT
}

export const getDealCoordinators = deal => {
  return deal.dealCoordinators ? deal.dealCoordinators.toString().replace(/,/g, ', ') : null
}
