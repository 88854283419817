import { setLocale } from 'yup'

setLocale({
  mixed: {
    required: 'Please fill in this field'
  },
  string: {
    // eslint-disable-next-line
    min: 'Please insert a value longer than ${min} characters',
    email: 'Please insert a valid email address',
    url: 'Please insert a valid URL'
  }
})
