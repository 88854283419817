import React, { lazy } from 'react'
import MainRoute from 'components/MainRoute/MainRoute'
import * as Routes from 'constants/Routes'

const DealListView = lazy(() => import('./components/DealListView/DealListView'))

export default (
  <MainRoute
    exact
    path={Routes.DEAL_LIST}
    isProtected
    component={DealListView}
  />
)
