import { useEffect } from 'react'

const useEventHandlers = (handlers) => {
  useEffect(() => {
    const handlersEntries = Object.entries(handlers)

    handlersEntries.forEach(([type, handle]) => {
      document.addEventListener(type, handle)
    })

    return () => {
      handlersEntries.forEach(([type, handle]) => {
        document.removeEventListener(type, handle)
      })
    }
  }, [])
}

export default useEventHandlers
