import React from 'react'
import styles from './ConflictIcon.scss'
import { AttentionIcon, CompleteCheckmarkIcon, InfoIcon, OverlayTrigger, Tooltip, RefreshIcon } from 'frontend-react-components'
import cx from 'classnames'
import Icon from 'components/Icon/Icon'
import IconSyncArrows from 'icons/SyncArrows.svg'

const TYPE_WARNING = 'warning'
const TYPE_ERROR = 'error'
const TYPE_SUCCESS = 'success'
const TYPE_PROGRESS = 'progress'
const TYPE_PROGRESS_ANIMATED = 'progressAnimated'
const TYPE_OVERRIDDEN = 'overridden'

const getIconFromType = type => {
  if (type === TYPE_WARNING) {
    return (
      <AttentionIcon status='incomplete' />
    )
  } else if (type === TYPE_ERROR) {
    return (
      <AttentionIcon status='error' />
    )
  } else if (type === TYPE_SUCCESS) {
    return (
      <CompleteCheckmarkIcon />
    )
  } else if (type === TYPE_PROGRESS) {
    return (
      <RefreshIcon />
    )
  } else if (type === TYPE_PROGRESS_ANIMATED) {
    return (
      <Icon
        className={styles.iconProgressAnimated}
        glyph={IconSyncArrows}
      />
    )
  } else if (type === TYPE_OVERRIDDEN) {
    return (
      <InfoIcon />
    )
  }
}

const ConflictIcon = ({
  id,
  type,
  className,
  tooltip
}) => {
  const icon = getIconFromType(type)

  const content = (
    <span
      id={id}
      className={cx(styles.component, {
        [styles[type]]: type,
        [className]: className
      })}
    >
      {icon}
    </span>
  )

  if (tooltip) {
    return (
      <OverlayTrigger
        overlay={(
          <Tooltip>
            {tooltip}
          </Tooltip>
        )}
      >
        {content}
      </OverlayTrigger>
    )
  }

  return content
}

ConflictIcon.TYPE_WARNING = TYPE_WARNING
ConflictIcon.TYPE_ERROR = TYPE_ERROR
ConflictIcon.TYPE_SUCCESS = TYPE_SUCCESS
ConflictIcon.TYPE_PROGRESS = TYPE_PROGRESS
ConflictIcon.TYPE_PROGRESS_ANIMATED = TYPE_PROGRESS_ANIMATED
ConflictIcon.TYPE_OVERRIDDEN = TYPE_OVERRIDDEN

export default ConflictIcon
