
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SwitchboardProjectExtensionsFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SwitchboardProjectExtensions"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"governingDeal"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"system"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"dealId"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"conflicts"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DataConflictFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"projectId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"entityUpdated"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"entityUpdatedId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mappings"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SwitchboardEntityMappingFragment"},"directives":[]}]}}]}}],"loc":{"start":0,"end":390}};
    doc.loc.source = {"body":"#import \"./DataConflictFragment.graphql\"\n#import \"./SwitchboardEntityMappingFragment.graphql\"\n\nfragment SwitchboardProjectExtensionsFragment on SwitchboardProjectExtensions {\n  governingDeal {\n    status\n    system\n    name\n    dealId\n  }\n  conflicts {\n    ...DataConflictFragment\n  }\n  projectId\n  entityUpdated\n  entityUpdatedId\n  mappings {\n    ...SwitchboardEntityMappingFragment\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./DataConflictFragment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./SwitchboardEntityMappingFragment.graphql").definitions));


      module.exports = doc;
    
