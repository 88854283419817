/* eslint-disable react/jsx-handler-names */

import React from 'react'
import styles from './Checkbox.scss'
import cx from 'classnames'
import { Form } from 'frontend-react-components'
import isFieldComponentEqual from 'utils/isFieldComponentEqual'

const Checkbox = ({
  field,
  label,
  type = 'checkbox',
  id,
  disabled,
  onClick
}) => {
  return (
    <div
      className={cx(styles.component, {
        [styles.hasLabel]: !!label
      })}
    >
      <Form.Check
        custom
        type={type}
        value={field?.value}
        checked={field?.value}
        onChange={field?.onChange}
        onBlur={field?.onBlur}
        id={id}
        label={label}
        onClick={onClick}
        disabled={disabled}
      />
    </div>
  )
}

Checkbox.displayName = 'Checkbox'

export default React.memo(Checkbox, isFieldComponentEqual)
