import React from 'react'
import styles from './Header.scss'
import { Link, NavLink } from 'react-router-dom'
import * as Routes from 'constants/Routes'
import Logo from './images/Logo.png'
import HeaderMenu from 'components/HeaderMenu/HeaderMenu'

const navItems = [{
  id: 'nav-deals',
  to: Routes.DEAL_LIST,
  label: 'Deals'
}, {
  id: 'nav-admin',
  to: Routes.ADMIN,
  label: 'Admin'
}]

const Header = () => {
  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <Link
          id='logo'
          to={Routes.DEAL_LIST}
        >
          <img
            className={styles.logo}
            src={Logo}
            alt='Switchboard'
          />
        </Link>
        <div className={styles.nav}>
          {navItems.map((navItem) => {
            return (
              <NavLink
                id={navItem.id}
                key={navItem.to}
                to={navItem.to}
                activeClassName={styles.navItemActive}
                className={styles.navItem}
              >
                {navItem.label}
              </NavLink>
            )
          })}
        </div>
        <div className={styles.menu}>
          <HeaderMenu />
        </div>
      </div>
    </div>
  )
}

export default React.memo(Header)
