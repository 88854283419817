import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import * as Routes from 'constants/Routes'

const Auth0Receiver = lazy(() => import('./components/Auth0Receiver/Auth0Receiver'))

export default (
  <Route
    exact
    path={Routes.AUTH0_RECEIVER}
    component={Auth0Receiver}
  />
)
