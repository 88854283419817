/*
 * DEPRECATED.
 *
 * This should no longer be used, and will be removed at a later time.
 */

const NAMESPACE = 'switchboard-web-auth'

export const setToken = (token /*, TODO: expires */) => {
  return localStorage.setItem(NAMESPACE, token)
}

export const getToken = () => {
  return localStorage.getItem(NAMESPACE)
}

export const clearToken = () => {
  return localStorage.removeItem(NAMESPACE)
}
