import isShallowEqual from './isShallowEqual'

const isFieldComponentEqual = ({
  field: prevField,
  ...prevProps
}, {
  field: nextField,
  ...nextProps
}) => {
  return isShallowEqual(prevProps, nextProps) && isShallowEqual(prevField, nextField)
}

export default isFieldComponentEqual
