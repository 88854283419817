const updateBreadcrumbLinks = async (_, { breadcrumbLinks }, { cache }) => {
  await cache.writeData({
    data: {
      breadcrumbLinks: breadcrumbLinks.map(b => ({
        ...b,
        isActive: b.isActive || false
      }))
    }
  })
  return null
}

export default updateBreadcrumbLinks
