import React from 'react'
import { Route } from 'react-router-dom'
import * as Routes from 'constants/Routes'

import Logout from './components/Logout/Logout'

export default (
  <Route
    exact
    path={Routes.LOGOUT}
    component={Logout}
  />
)
