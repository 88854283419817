import React from 'react'
import cx from 'classnames'
import styles from './Textarea.scss'
import _ from 'lodash'
import { Form } from 'frontend-react-components'
import isFieldComponentEqual from 'utils/isFieldComponentEqual'

const Textarea = ({
  field,
  form,
  className,
  innerRef,
  isResizable = true,
  disabled,
  maxLength,
  placeholder,
  rows,
  id
}) => {
  const hasTouched = _.has(form?.touched, field.name)
  const hasError = _.has(form?.errors, field.name)

  return (
    <div
      className={cx({
        [styles.hasError]: hasTouched && hasError,
        [styles.isResizable]: isResizable
      })}
    >
      <div className={styles.container}>
        <Form.Control
          as='textarea'
          {...field}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholder}
          ref={innerRef}
          rows={rows}
          id={id}
          className={cx(className, styles.textarea)}
        />
      </div>
    </div>
  )
}

Textarea.displayName = 'Textarea'

export default React.memo(Textarea, isFieldComponentEqual)
