import * as SharingStatus from 'constants/SharingStatus'
import * as ConflictType from 'constants/ConflictType'
import * as EntityType from 'constants/EntityType'
import * as DealType from 'constants/DealType'
import {
  getErrorConflicts,
  getRegularConflicts
} from './conflicts'

export const getEntityTypeFromTypename = entity => {
  if (!entity.__typename) {
    // eslint-disable-next-line no-console
    console.warn('The entity did not have an __typename, please double check all <EntityPageView> components.')
    return null
  }

  switch (entity.__typename) {
    case 'Project':
      return EntityType.PROJECT
    case 'Product':
      return EntityType.PRODUCT
    case 'Deal':
      return EntityType.DEAL
    case 'Track':
      return EntityType.TRACK
    case 'PublisherMapping':
      return EntityType.PUBLISHER_MAPPING
    case 'Publisher':
      return EntityType.PUBLISHER
  }
}

export const getHasConflicts = entity => {
  return getRegularConflicts(entity)?.length > 0
}

export const getHasErrorConflicts = entity => {
  return getErrorConflicts(entity)?.length > 0
}

export const getHasOnlyErrorConflicts = entity => {
  const conflicts = entity?.switchboardExtensions?.conflicts

  if (!conflicts || !conflicts.length) {
    return false
  }

  for (const conflict of conflicts) {
    if (conflict.type !== ConflictType.PROCESSING_ERROR) {
      return false
    }
  }

  return true
}

export const getLatestDdexIngestionResults = deal => {
  let dealProducts = []
  if (deal?.type === DealType.PROJECT && deal?.subject?.products?.length) {
    const products = deal?.subject?.products

    dealProducts = products.filter(p => {
      if (!p?.switchboardExtensions) return false
      if (p?.switchboardExtensions) {
        if (p.switchboardExtensions.governingDeal?.dealId !== deal?.dealId) {
          return false
        }
      }
      return true
    })
  } else {
    dealProducts.push(deal.subject)
  }

  const results = dealProducts.filter(p => {
    if (!p) return false
    if (p.switchboardExtensions.ddexIngestionResults?.length > 0) return true
  }).map(p => {
    return p.switchboardExtensions.ddexIngestionResults[0]
  })
  return results
}

const getNestedEntitiesSharingStatuses = (entity) => {
  const entityType = getEntityTypeFromTypename(entity)
  if (entityType === EntityType.TRACK) {
    return []
  }
  if (entityType !== EntityType.PRODUCT) {
    throw new Error('Unimplemented.')
  }

  return entity?.components
    ?.flatMap(component => component.sides)
    .flatMap(side => side.productTracks)
    .flatMap(productTrack => productTrack.track)
    .map(track => {
      return getSharingStatus(track)
    })
}

export const getSharingStatus = entity => {
  const entityType = getEntityTypeFromTypename(entity)

  if (!entity.switchboardExtensions) {
    return SharingStatus.NOT_SHARING
  }

  // Projects don't depend on nested entities to be sharing
  // TODO Remove in SWB-38 because ProductTrack errors are available at the Product level.
  if (entityType !== EntityType.PROJECT) {
    const nestedEntitiesSharingStatuses = getNestedEntitiesSharingStatuses(entity)

    if (nestedEntitiesSharingStatuses?.length) {
      if (nestedEntitiesSharingStatuses.includes(SharingStatus.ERROR)) {
        return SharingStatus.ERROR
      } else if (nestedEntitiesSharingStatuses.includes(SharingStatus.CONFLICTED)) {
        return SharingStatus.CONFLICTED
      }
    }
  }

  const hasErrorConflicts = getHasErrorConflicts(entity)

  if (hasErrorConflicts) {
    return SharingStatus.ERROR
  }

  const hasConflicts = getHasConflicts(entity)

  if (!hasConflicts) {
    const hasAllSystemIds = entity?.ids?.length === 2

    if (hasAllSystemIds) {
      return SharingStatus.SHARED
    }

    return SharingStatus.SHARING
  }

  return SharingStatus.CONFLICTED
}
