import React from 'react'
import { Transition } from 'react-transition-group'
import styles from './Fade.scss'
import cx from 'classnames'

const Fade = ({ in: inProp, timeout = 400, children, appear }) => (
  <Transition
    in={inProp}
    timeout={timeout}
    appear={appear}
  >
    {state => (
      <div
        className={cx(styles.component, {
          [styles[`component--${state}`]]: state
        })}
      >
        {children}
      </div>
    )}
  </Transition>
)

export default Fade
