import * as ConflictType from '../constants/ConflictType'
import * as EntityStatus from '../constants/EntityStatus'
import * as DealType from '../constants/DealType'
import ConflictIcon from '../components/ConflictIcon/ConflictIcon'
import { CompleteCheckmarkIcon } from 'frontend-react-components'
import styles from '../components/ResponsibilitiesApplyTo/ResponsibilitiesApplyTo.scss'
import React from 'react'
import { getIsDealDraft } from './deal'

export const getConflictsByType = (entity, types) => {
  const conflicts = entity?.switchboardExtensions?.conflicts

  if (!conflicts) {
    return null
  }

  return conflicts
    .filter(conflict => {
      return types.includes(conflict.type)
    })
}

export const getDdexIngestionResults = (entity) => {
  const results = entity?.switchboardExtensions?.ddexIngestionResults

  if (!results) {
    return null
  }

  return results
}

export const getParticipantConflicts = entity => {
  return getConflictsByType(entity, [
    ConflictType.PARTICIPANT_MAPPING_REQUIRED
  ])
}

export const getUniqueParticipantConflicts = entity => {
  const participantConflicts = getParticipantConflicts(entity)

  if (!participantConflicts) {
    return []
  }

  const uniqueParticipantConflicts = {}

  for (const currentParticipantConflict of participantConflicts) {
    const name = currentParticipantConflict?.conflictedEntity?.name

    if (!name) {
      // eslint-disable-next-line no-console
      console.warn('Participant conflict was missing a conflictedEntity or conflictedEntity.name', currentParticipantConflict)
      continue
    }

    if (!uniqueParticipantConflicts[name]) {
      uniqueParticipantConflicts[name] = currentParticipantConflict
    } else {
      const hasRepertoireOwner = currentParticipantConflict?.conflictedEntity?.repertoireOwner

      if (hasRepertoireOwner) {
        uniqueParticipantConflicts[name] = currentParticipantConflict
      }
    }
  }

  return Object.values(uniqueParticipantConflicts)
}

export const getProjectConflicts = entity => {
  return getConflictsByType(entity, [
    ConflictType.PROJECT_MAPPING_REQUIRED
  ])
}

export const getTrackConflicts = entity => {
  return getConflictsByType(entity, [
    ConflictType.INVALID_ISRC
  ])
}

export const getRegularConflicts = entity => {
  return getConflictsByType(entity, [
    ConflictType.PARTICIPANT_MAPPING_REQUIRED
  ])
}

export const getErrorConflicts = entity => {
  return getConflictsByType(entity, [
    ConflictType.PROCESSING_ERROR,
    ConflictType.INVALID_ISRC,
    ConflictType.PROJECT_MAPPING_REQUIRED,
    ConflictType.PARTICIPANT_MAPPING_REQUIRED,
    ConflictType.UNSUPPORTED_PRODUCT_TYPE,
    ConflictType.UNSUPPORTED_RELEASE_TYPE
  ])
}

export const getPublisherConflicts = entity => {
  return getConflictsByType(entity, [
    ConflictType.PUBLISHER_MAPPING_REQUIRED
  ])
}

export const getUniquePublisherConflicts = entity => {
  const publisherConflicts = getPublisherConflicts(entity)

  if (!publisherConflicts) {
    return []
  }

  const uniquePublisherConflicts = {}

  for (const currentPublisherConflict of publisherConflicts) {
    const name = currentPublisherConflict?.conflictedEntity?.name

    if (!name) {
      // eslint-disable-next-line no-console
      console.warn('Participant conflict was missing a conflictedEntity or conflictedEntity.name', currentPublisherConflict)
      continue
    }

    if (!uniquePublisherConflicts[name]) {
      uniquePublisherConflicts[name] = currentPublisherConflict
    }
  }

  return Object.values(uniquePublisherConflicts)
}

export const getConflictIcon = ({
  entityStatus,
  id,
  hideComplete = false,
  deal
}) => {
  if (entityStatus === EntityStatus.ERROR) {
    return (
      <ConflictIcon
        id={id}
        tooltip='Processing errors are stopping this entity from being shared'
        type={ConflictIcon.TYPE_ERROR}
      />
    )
  } else if (entityStatus === EntityStatus.CONFLICT) {
    return (
      <ConflictIcon
        id={id}
        tooltip='A data conflict is stopping this entity from being shared'
        type={ConflictIcon.TYPE_WARNING}
      />
    )
  } else if (entityStatus === EntityStatus.INGESTING) {
    if (deal && getIsDealDraft(deal)) {
      return null
    }

    return (
      <ConflictIcon
        id={id}
        tooltip='Ingesting...'
        type={ConflictIcon.TYPE_PROGRESS_ANIMATED}
      />
    )
  } else if (hideComplete) {
    return null
  } else if (entityStatus === EntityStatus.COMPLETE) {
    return (
      <CompleteCheckmarkIcon
        className={styles.iconShared}
      />
    )
  }
}

export const getSystemFromEntity = entity => {
  if (!entity?.switchboardExtensions?.conflicts?.length) {
    return null
  }

  return entity?.switchboardExtensions?.conflicts[0]?.conflictedEntity?.ids[0]?.system
}

export const getDealConflictCount = deal => {
  let conflictCount = 0

  const subjectConflictCount = deal?.subject?.switchboardExtensions?.conflicts?.length

  if (subjectConflictCount > 0) {
    conflictCount++
  }

  if (deal?.type === DealType.PROJECT && deal?.subject?.products?.length) {
    const products = deal?.subject?.products

    products.forEach(p => {
      if (p?.switchboardExtensions) {
        if (p.switchboardExtensions.governingDeal?.dealId !== deal?.dealId) {
          return
        }
        const projectConflictCount = p.switchboardExtensions.conflicts?.length

        if (projectConflictCount > 0) {
          conflictCount++
        }
      }
    })
  }

  return conflictCount
}

export const getDealProductErrorCount = deal => {
  let productErrorCount = 0

  if (deal?.type === DealType.PROJECT && deal?.subject?.products?.length) {
    const products = deal?.subject?.products

    products.forEach(p => {
      if (p?.switchboardExtensions) {
        if (p.switchboardExtensions.governingDeal?.dealId !== deal?.dealId) {
          return
        }

        const ddexIngestionResultCount = p.switchboardExtensions.ddexIngestionResults?.length

        if (ddexIngestionResultCount > 0) {
          if (p.switchboardExtensions.ddexIngestionResults[0].status === 'FAILURE') {
            productErrorCount++
          }
        }
      }
    })
  }

  return productErrorCount
}

export const getIsEntityConflicted = entity => {
  return entity?.switchboardExtensions?.conflicts?.length > 0
}

export const getIsProductErrored = product => {
  const ddexIngestionResultCount = product.switchboardExtensions?.ddexIngestionResults?.length
  if (ddexIngestionResultCount > 0) {
    if (product.switchboardExtensions.ddexIngestionResults[0].status === 'FAILURE') {
      return true
    }
  }
  return false
}
