import 'babel-polyfill'
import 'whatwg-fetch'

import React from 'react'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'

import 'utils/setupYup'
import 'utils/setupToast'

import Auth0Provider from 'components/Auth0Provider/Auth0Provider'
import Auth0ApolloProvider from 'components/Auth0ApolloProvider/Auth0ApolloProvider'
import pages from './pages'
import * as Sentry from '@sentry/browser'
import * as config from 'config'

import './index.scss'

if (config.SENTRY_DSN !== undefined) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    release: `switchboard-frontend@${COMMITHASH}`
  })
}

export const rootElement = document.getElementById('root')

function renderApp (pages) {
  render(
    <Auth0Provider>
      <AppContainer>
        <Auth0ApolloProvider>
          {pages}
        </Auth0ApolloProvider>
      </AppContainer>
    </Auth0Provider>,
    rootElement
  )
}

renderApp(pages)

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept('./pages', () => {
      const newPages = require('./pages').default

      try {
        renderApp(newPages)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Routes hot reloading error: ${error}`)
      }
    })
  }
}
