import operations from 'operations'
import { useAuth0 } from 'hooks/useAuth0'
import { useQuery } from '@apollo/react-hooks'
import { useState, useEffect } from 'react'

const useMe = ({ onError } = {}) => {
  const [isLoading, setLoading] = useState(true)
  const [dataCache, setDataCache] = useState(null)

  const {
    loading: auth0Loading
  } = useAuth0()

  const {
    loading: getMeLoading,
    data
  } = useQuery(operations.getMe, {
    skip: auth0Loading,
    fetchPolicy: 'network-only',
    pollInterval: 60 * 1000,
    onError: onError || null
  })

  useEffect(() => {
    if (!getMeLoading) {
      setDataCache(data)
      setLoading(false)
    }
  }, [data])

  return {
    isLoading: isLoading,
    me: dataCache?.getMe
  }
}

export default useMe
