import React from 'react'
import { DayInput } from 'frontend-react-components'
import isFieldComponentEqual from 'utils/isFieldComponentEqual'

const Date = ({
  form,
  field,
  innerRef,
  id,
  ...props
}) => {
  const handleChange = value => {
    form.setFieldValue(field.name, value)
  }

  return (
    <div id={id}>
      <DayInput
        ref={innerRef}
        name={field.name}
        onChange={handleChange}
        value={field.value}
        {...props}
      />
    </div>
  )
}

Date.displayName = 'Date'

export default React.memo(Date, isFieldComponentEqual)
