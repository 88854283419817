import React, { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import Layout from 'components/Layout/Layout'
import PageLoader from 'components/PageLoader/PageLoader'

import * as Routes from 'constants/Routes'

import Auth0Receiver from 'pages/Auth0Receiver'
import Deal from 'pages/Deal'
import DealList from 'pages/DealList'
import DealCreate from 'pages/DealCreate'
import Admin from 'pages/Admin'
import Project from 'pages/Product'
import Product from 'pages/Project'
import Login from 'pages/Login'
import Logout from 'pages/Logout'
import NotFound from 'pages/NotFound'
import UnknownUser from 'pages/UnknownUser'
import Refresh from 'pages/Refresh'
import { TransitionGroup } from 'react-transition-group'
import { ModalProvider } from 'react-modal-hook'

export default (
  <Layout>
    <Router>
      <ModalProvider container={TransitionGroup}>
        <LastLocationProvider>
          <Suspense
            fallback={(
              <PageLoader />
            )}
          >
            <Switch>
              <Refresh path={Routes.REFRESH} />
              <Redirect
                from={Routes.HOME}
                exact
                to={Routes.DEAL_LIST}
              />
              {Auth0Receiver}
              {Deal}
              {Project}
              {Product}
              {DealList}
              {DealCreate}
              {Admin}
              {Login}
              {Logout}
              {UnknownUser}
              {NotFound}
            </Switch>
          </Suspense>
        </LastLocationProvider>
      </ModalProvider>
    </Router>
  </Layout>
)
