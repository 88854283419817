import { useAuth0 } from 'hooks/useAuth0'

const useUser = () => {
  const auth0 = useAuth0()

  if (auth0?.user) {
    return {
      name: auth0?.user?.nickname || auth0?.user?.email,
      email: auth0?.user?.email
    }
  }

  return {
    name: '-',
    email: '-'
  }
}

export default useUser
