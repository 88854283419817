import React, { useCallback } from 'react'
import styles from './AsyncSelect.scss'
import ReactAsyncSelect from 'react-select/async'
import cx from 'classnames'
import { SearchIcon, CloseIcon } from 'frontend-react-components'
import debounce from 'es6-promise-debounce'
import Spinner from 'components/Spinner/Spinner'
import isFieldComponentEqual from 'utils/isFieldComponentEqual'

const DEFAULT_MIN_CHAR = 3
const DEFAULT_DEBOUNCE_INTERVAL = 200

const customStyles = {
  input: () => {
    return {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 2,
      marginRight: 2
    }
  }
}

const getMinCharLabel = minChar => {
  return `Keep typing, ${minChar} characters required.`
}

const LoadingMessage = props => {
  const {
    inputValue,
    minChar
  } = props.selectProps

  const content = inputValue.length <= minChar
    ? getMinCharLabel(minChar)
    : props.children

  return (
    <div className={styles.minChar}>
      {content}
    </div>
  )
}

const GroupHeading = ({
  children,
  id
}) => {
  return (
    <div
      id={id}
      className={styles.groupHeader}
    >
      {children}
    </div>
  )
}

const LoadingIndicator = (props) => {
  const {
    inputValue,
    minChar
  } = props.selectProps

  if (inputValue.length < minChar) {
    return null
  }

  return (
    <div className={styles.loader}>
      <Spinner primary />
    </div>
  )
}

const ClearIndicator = ({
  innerProps,
  selectProps
}) => {
  const handleMouseDown = (e) => {
    innerProps.onMouseDown(e)
    selectProps.onClear && selectProps.onClear()
  }

  const handleTouchEnd = (e) => {
    innerProps.onTouchEnd(e)
  }

  return (
    <button
      type='button'
      className={styles.iconClear}
      onMouseDown={handleMouseDown}
      onTouchEnd={handleTouchEnd}
    >
      <CloseIcon />
    </button>
  )
}

const NoOptionsMessage = (props) => {
  const {
    inputValue,
    minChar
  } = props.selectProps

  if (inputValue === '') {
    return (
      <div className={styles.minChar}>
        {getMinCharLabel(minChar)}
      </div>
    )
  }

  return (
    <div className={styles.empty}>
      No results found.
    </div>
  )
}

const Option = ({
  innerProps,
  innerRef,
  isDisabled,
  data,
  children,
  isSelected,
  isFocused
}) => {
  if (data?.isMinChars) {
    return (
      <div className={styles.minChar}>
        {children}
      </div>
    )
  }

  if (isDisabled) {
    return (
      <div className={styles.optionDisabled}>
        {children}
      </div>
    )
  }

  return (
    <div
      ref={innerRef}
      className={cx(styles.option, {
        [styles.isSelected]: isSelected,
        [styles.isFocused]: isFocused,
        [styles.hasControls]: !!data.controls
      })}
      {...innerProps}
    >
      <div className={styles.optionInfo}>
        <div className={styles.optionInfoPrimary}>
          {children}
          {data.labelPostfix && ` ${data.labelPostfix}`}
        </div>
        {data.secondary && (
          <div className={styles.optionInfoSecondary}>
            {data.secondary}
          </div>
        )}
      </div>
      {data.controls && (
        <div className={styles.optionControls}>
          {data.controls}
        </div>
      )}
    </div>
  )
}

const getMinCharsOption = minChar => {
  return [{
    isMinChars: true,
    label: getMinCharLabel(minChar)
  }]
}

const AsyncSelect = React.forwardRef(({
  field,
  form,
  loadOptions,
  placeholder,
  onChange,
  onClear,
  isDisabled,
  hasIconSearch,
  hasGroupOptions,
  minChar = DEFAULT_MIN_CHAR,
  debounceDelay = DEFAULT_DEBOUNCE_INTERVAL,
  id
}, ref) => {
  const handleChange = option => {
    // Triggered when clear icon click
    if (option === null) {
      return form.setFieldValue(
        field.name,
        null
      )
    }

    form.setFieldValue(
      field.name,
      option
    )
  }

  const minCharsOption = useCallback(getMinCharsOption(minChar), [
    minChar
  ])

  const debouncedHandleLoadOptions = useCallback(
    debounce(loadOptions, debounceDelay), [
      loadOptions
    ]
  )

  const handleLoadOptions = useCallback(async (inputValue) => {
    if (inputValue.length < minChar) {
      return minCharsOption
    }

    return debouncedHandleLoadOptions(inputValue)
  }, [
    loadOptions
  ])

  const hasTouched = !!form.touched[field.name]
  const hasError = !!form.errors[field.name]

  return (
    <div
      className={cx(styles.component, {
        [styles.hasError]: hasTouched && hasError,
        [styles.hasIconSearch]: hasIconSearch,
        [styles.isDisabled]: isDisabled,
        [styles.hasGroupOptions]: hasGroupOptions
      })}
    >
      {hasIconSearch && (
        <div className={styles.iconSearch}>
          <SearchIcon />
        </div>
      )}
      <ReactAsyncSelect
        ref={ref}
        minChar={minChar}
        isClearable
        value={field.value}
        isDisabled={isDisabled}
        classNamePrefix='react-select'
        styles={customStyles}
        loadOptions={handleLoadOptions}
        placeholder={placeholder}
        onChange={handleChange}
        onClear={onClear}
        id={id}
        components={{
          Option,
          GroupHeading,
          LoadingIndicator,
          LoadingMessage,
          ClearIndicator,
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          NoOptionsMessage
        }}
      />
    </div>
  )
})

AsyncSelect.displayName = 'AsyncSelect'

export default React.memo(AsyncSelect, isFieldComponentEqual)
