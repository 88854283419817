import React, { lazy } from 'react'
import MainRoute from 'components/MainRoute/MainRoute'
import * as Routes from 'constants/Routes'

const AdminView = lazy(() => import('./components/AdminView/AdminView'))

export default (
  <MainRoute
    path={Routes.ADMIN}
    isProtected
    component={AdminView}
  />
)
