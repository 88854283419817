import React, { lazy } from 'react'
import MainRoute from 'components/MainRoute/MainRoute'
import * as Routes from 'constants/Routes'

const ProjectView = lazy(() => import('./components/ProjectView/ProjectView'))

export default (
  <MainRoute
    path={Routes.PROJECT}
    isProtected
    component={ProjectView}
  />
)
