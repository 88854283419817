import React, { useCallback } from 'react'
import { AsyncSelect } from 'components/FormElements'
import * as System from 'constants/System'
import operations from 'operations'
import { useApolloClient } from '@apollo/react-hooks'
import * as DealOwnerOptionType from 'constants/DealOwnerOptionType'
import isFieldComponentEqual from '../../../../utils/isFieldComponentEqual'
import { getProductArtistName } from '../../../../utils/data'

const getProductAndReleaseTypeLabel = product => {
  if (!product?.productType && !product?.releaseType) {
    return ''
  }

  let label = ''

  if (product?.productType) {
    label += product?.productType
  }

  if (product?.releaseType) {
    if (label.length > 0) {
      label += ' '
    }

    label += product.releaseType
  }

  return (
    <span>
      &nbsp;({label})
    </span>
  )
}

const getOptionsFromOperationResults = ({
  labels,
  projects,
  products = {}
}) => {
  const output = []

  if (labels?.length) {
    output.push({
      label: 'Labels',
      options: labels?.map(r => {
        const value = r.companyCode

        return {
          type: DealOwnerOptionType.LABEL,
          value,
          label: r?.name,
          controls: (
            <span>ID {value}</span>
          )
        }
      })
    })
  } else {
    output.push({
      label: 'Labels',
      options: [{
        value: 'No results',
        label: 'No results',
        isDisabled: true
      }]
    })
  }

  if (projects?.length) {
    output.push({
      label: 'Projects',
      options: projects?.map(r => {
        const value = r?.ids[0]?.localId
        const artistName = r?.artist?.name
        const maintenanceOwner = r?.maintenanceOwner?.name
        const projectCode = r?.projectCode
        const labelName = r?.labelAccount?.name

        return {
          type: DealOwnerOptionType.PROJECT,
          value,
          label: r?.name,
          labelPostfix: projectCode && `(PC ${projectCode})`,
          controls: (
            <span>ID {value}</span>
          ),
          secondary: (
            <span>
              {artistName}
              {labelName && (
                <span>
                  {' • '}
                  {labelName}
                </span>
              )}
              {maintenanceOwner && (
                <span>
                  {' • '}
                  {maintenanceOwner}
                </span>
              )}
            </span>
          )
        }
      })
    })
  } else {
    output.push({
      label: 'Projects',
      options: [{
        value: 'No results',
        label: 'No results',
        isDisabled: true
      }]
    })
  }

  if (products?.length) {
    output.push({
      label: 'Products',
      options: products?.map(r => {
        const value = r?.upc
        const artistName = getProductArtistName(r)

        return {
          type: DealOwnerOptionType.PRODUCT,
          value,
          label: r?.formalTitle?.titleText,
          controls: r?.upc ? (
            <span>UPC {value}</span>
          ) : null,
          secondary: (
            <span>
              {artistName}
              {r?.labelName && (
                <span>
                  {' • '}
                  {r?.labelName}
                </span>
              )}
              {getProductAndReleaseTypeLabel(r)}
            </span>
          )
        }
      })
    })
  } else {
    output.push({
      label: 'Products',
      options: [{
        value: 'No results',
        label: 'No results',
        isDisabled: true
      }]
    })
  }

  return output
}

const getSearchOperation = system => {
  if (system === System.ORCHARD) {
    return operations.searchDealSubjectOrchard
  } else if (system === System.SONY) {
    return operations.searchDealSubjectSony
  }

  throw new Error(`Invalid system: ${system}, could not resolve the search operation.`)
}

const getSearchResultsFromOperationResponse = (system, res) => {
  if (system === System.ORCHARD) {
    const { getOrchardLabelSearch, getProjectSearch, getProductSearch } = res.data

    return getOptionsFromOperationResults({
      labels: getOrchardLabelSearch?.results,
      projects: getProjectSearch?.results,
      products: getProductSearch?.results
    })
  } else if (system === System.SONY) {
    const { getProjectSearch, getProductSearch } = res.data

    return getOptionsFromOperationResults({
      projects: getProjectSearch?.results,
      products: getProductSearch?.results,

      // TODO:
      labels: []
    })
  }

  throw new Error(`Invalid system: ${system}, could not return search results.`)
}

const getSearchResults = async (system, searchText, client) => {
  const operation = getSearchOperation(system)

  const res = await client.query({
    query: operation,
    variables: {
      searchText
    }
  })

  return getSearchResultsFromOperationResponse(system, res)
}

const DealSubjectSelect = ({
  form,
  field,
  system,
  innerRef,
  ...props
}) => {
  const client = useApolloClient()

  const loadOptions = useCallback(async (inputValue) => {
    return getSearchResults(system, inputValue, client)
  }, [
    system
  ])

  return (
    <AsyncSelect
      ref={innerRef}
      placeholder='Select a label, project or product'
      hasIconSearch
      hasGroupOptions
      isDisabled={system === null}
      form={form}
      field={field}
      loadOptions={loadOptions}
      {...props}
    />
  )
}

DealSubjectSelect.displayName = 'DealSubjectSelect'

export default React.memo(DealSubjectSelect, isFieldComponentEqual)
