import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { withClientState } from 'apollo-link-state'
import { ApolloLink } from 'apollo-link'
import uuid from 'uuid/v1'
import fragmentMatcher from './fragmentMatcher'
import resolvers from 'resolvers'
import { onError } from 'apollo-link-error'
import { API_URL } from '../../config'
import { toast } from 'react-toastify'
import * as System from 'constants/System'
import * as Sentry from '@sentry/browser'
import ApolloLinkTimeout from 'apollo-link-timeout'

const DEFAULT_TIMEOUT_TIME = 55 * 1000

const getSystemFromError = message => {
  if (message.includes('[ows')) {
    return System.ORCHARD
  }

  // TODO: figure out more reliable cases to check on

  return null
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError?.message === 'Timeout exceeded') {
    toast.error('The server took too long to respond, please try again later.')
  }

  if (graphQLErrors) {
    graphQLErrors.map(error => {
      const { message } = error
      const system = getSystemFromError(message)

      if (system) {
        Sentry.withScope(scope => {
          scope.setTag('component', 'graphql-errors')
          error.system = system
          Sentry.captureException(error)
        })

        toast.error(message)
      }
    })
  }
})

export let lastUsedCorrelationId = null

const httpLink = createHttpLink({
  uri: API_URL
})

const timeoutLink = new ApolloLinkTimeout(DEFAULT_TIMEOUT_TIME)
const timeoutHttpLink = timeoutLink.concat(httpLink)

const omitTypename = (key, value) => {
  return key === '__typename' ? undefined : value
}

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
  }

  return forward(operation)
})

const createAuthLink = tokenContext => setContext((_, { headers }) => {
  const token = tokenContext.current

  lastUsedCorrelationId = uuid()

  headers = {
    ...headers,
    'Correlation-Id': lastUsedCorrelationId
  }

  if (token) {
    return {
      headers: {
        ...headers,
        'x-token': token
      }
    }
  }

  return headers
})

const cache = new InMemoryCache({
  fragmentMatcher
})

const stateLink = withClientState({
  cache
})

const createApolloClient = tokenRef => new ApolloClient({
  name: 'switchboard-frontend',
  version: process.env.GIT_COMMIT || 'no-version',
  cache,
  link: ApolloLink.from([
    stateLink,
    omitTypenameLink,
    errorLink,
    createAuthLink(tokenRef)
      .concat(timeoutHttpLink)
  ]),
  resolvers,
  defaultOptions: {
    query: {
      errorPolicy: 'all'
    }
  }
})

export default createApolloClient
