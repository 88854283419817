import React, { useState, useEffect } from 'react'
import { useTransition, animated } from 'react-spring'
import styles from './Errors.scss'
import { Alert } from 'frontend-react-components'

const config = {
  tension: 150,
  friction: 20,
  precision: 0.1
}

const Errors = ({
  errors,
  getErrorMessage
}) => {
  const [refMap] = useState(() => new WeakMap())
  const [items, setItems] = useState([])

  const transitions = useTransition(items, item => item.message, {
    from: {
      opacity: 0,
      height: 0,
      transform: 'translateY(-40px) rotate3d(1, 0, 0, -90deg)'
    },
    enter: item => async next => next({
      opacity: 1,
      height: refMap.get(item).offsetHeight,
      transform: 'translateY(0) rotate3d(0, 0, 0, 0deg)'
    }),
    leave: item => async (next) => {
      await next({ opacity: 0 })
      await next({ height: 0 })
    },
    config
  })

  useEffect(() => {
    if (errors !== undefined) {
      setItems(errors)
    }
  }, [errors])

  return (
    <div className={styles.component}>
      {transitions.map(({ key, item, props: { ...style } }) => (
        <animated.div
          key={key}
          style={style}
        >
          <div ref={ref => ref && refMap.set(item, ref)}>
            <Alert
              variant='danger'
            >
              {getErrorMessage(item)}
            </Alert>
          </div>
        </animated.div>
      ))}
    </div>
  )
}

Errors.displayName = 'Errors'

export default React.memo(Errors)
