import React, { useCallback, useState, useEffect } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import * as Sentry from '@sentry/browser'

import {
  AUTH0_AUDIENCE,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID
} from 'config'
import * as Routes from 'constants/Routes'
import { Auth0Context } from 'hooks/useAuth0'
import { getToken } from 'utils/authStorage'

const Auth0Provider = ({
  children
}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [auth0Client, setAuth0] = useState()
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(getToken())
  const loginWithRedirect = useCallback(() => {
    return auth0Client.loginWithRedirect({
      audience: AUTH0_AUDIENCE
    })
  }, [auth0Client])

  const logout = useCallback(() => {
    return auth0Client.logout({
      federated: true,
      returnTo: `${window.location.origin}${Routes.LOGIN}`
    })
  }, [auth0Client])

  useEffect(() => {
    if (!user) {
      return
    }

    Sentry.configureScope(scope => {
      scope.setUser({
        email: user?.email,
        id: user?.sub
      })
    })
  }, [user])

  useEffect(() => {
    let interval
    (async () => {
      const auth0FromHook = await createAuth0Client({
        domain: AUTH0_DOMAIN,
        client_id: AUTH0_CLIENT_ID,
        audience: AUTH0_AUDIENCE,
        redirect_uri: `${window.location.origin}${Routes.AUTH0_RECEIVER}`
      })

      setAuth0(auth0FromHook)

      if (token) {
        setLoading(false)
        return
      }

      try {
        if (window.location.search.includes('code=')) {
          await auth0FromHook.handleRedirectCallback()
        }

        let user = await auth0FromHook.getUser()
        setUser(user)

        const token = await auth0FromHook.getTokenSilently()
        setToken(token)

        if (token) {
          interval = setInterval(async () => {
            try {
              const token = await auth0FromHook.getTokenSilently({
                audience: AUTH0_AUDIENCE,
                ignoreCache: true
              })
              setToken(token)
              const newUser = await auth0FromHook.getUser()
              if (newUser.sub !== user.sub) {
                user = newUser
                setUser(user)
              }
            } catch (err) {
              if (err.error && err.error === 'login_required') {
                // Auth0 session has expired, try to redirect to the login page
                auth0FromHook.loginWithRedirect({
                  audience: AUTH0_AUDIENCE
                })
                return
              }
              Sentry.withScope(scope => {
                scope.setTag('component', 'auth0-refresh')
                Sentry.captureException(err)
              })
            }
          }, 5 * 60 * 1000)
        }
      } catch (err) {
        setError(err)
        throw err
      } finally {
        setLoading(false)
      }
    })()

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return (
    <Auth0Context.Provider
      value={{
        error,
        token,
        loading,
        loginWithRedirect,
        logout,
        user
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

export default Auth0Provider
