import * as System from 'constants/System'
import * as EntityStatus from 'constants/EntityStatus'
import * as EntityType from 'constants/EntityType'
import { getHasOnlyErrorConflicts, getLatestDdexIngestionResults, getEntityTypeFromTypename } from 'utils/switchboardExtensions'
import { getIsDealDraft } from './deal'

export const getOppositeSystem = sourceSystem => {
  if (sourceSystem === System.ORCHARD) {
    return System.SONY
  } else if (sourceSystem === System.SONY) {
    return System.ORCHARD
  }
}

export const getSystemLabel = system => {
  if (system === System.ORCHARD) {
    return 'The Orchard'
  } else if (system === System.SONY) {
    return 'Sony Music'
  }
}

export const getProductArtistName = product => {
  return product?.displayArtist?.name || product?.displayArtistName || null
}

export const getIsIngesting = (entity) => {
  if (entity?.switchboardExtensions?.mappings) {
    return entity.switchboardExtensions.mappings.some(({
      lastPublished,
      lastProcessed
    }) => {
      if (!lastPublished) {
        return false
      }
      if (!lastProcessed) {
        return true
      }
      return lastPublished > lastProcessed
    })
  }

  return false
}
/**
 * Notes on project deals
 * - If at least 1 product has an error Status = Error
 * - If at least 1 product is complete and none in error Status = complete
 * - If no statuses Status = Ingesting *
 */

export const getDealEntityStatus = (entity, deal) => {
  const ddexIngestionResults = getLatestDdexIngestionResults(deal)

  if (ddexIngestionResults.some(r => r.status === 'FAILURE')) {
    return EntityStatus.ERROR
  }

  if (ddexIngestionResults.some(r => r.status === 'SUCCESS')) {
    return EntityStatus.COMPLETE
  }
  return null
}

export const getProductStatus = (product) => {
  if (!product) return EntityStatus.ERROR
  const results = product.switchboardExtensions?.ddexIngestionResults
  if (!results || results < 1) {
    return EntityStatus.STAND_BY
  }

  if (results[0].status === 'FAILURE') {
    return EntityStatus.ERROR
  }
  return EntityStatus.COMPLETE
}

export const getEntityStatus = (entity, deal) => {
  if (getEntityTypeFromTypename !== EntityType.PRODUCT) return null
  const hasOnlyErrorConflicts = getHasOnlyErrorConflicts(entity)

  if (hasOnlyErrorConflicts) {
    return EntityStatus.ERROR
  }

  const switchboardExtensions = entity?.switchboardExtensions
  const conflictCount = switchboardExtensions?.conflicts?.length || 0

  if (conflictCount > 0) {
    return EntityStatus.CONFLICT
  }

  const idCount = entity?.ids?.length || 1
  if (!switchboardExtensions || idCount <= 1) {
    return EntityStatus.STAND_BY
  }

  const isIngesting = getIsIngesting(entity)

  if (isIngesting) {
    if (deal && getIsDealDraft(deal)) {
      return EntityStatus.STAND_BY
    }

    return EntityStatus.INGESTING
  }

  return EntityStatus.COMPLETE
}
