
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"DealResponsibilityFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DealResponsibility"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"appliesTo"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"assignments"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AssignmentFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"comments"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"dealResponsibilityType"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DealResponsibilityTypeFragment"},"directives":[]}]}}]}}],"loc":{"start":0,"end":291}};
    doc.loc.source = {"body":"#import \"./DealResponsibilityTypeFragment.graphql\"\n#import \"./AssignmentFragment.graphql\"\n\nfragment DealResponsibilityFragment on DealResponsibility {\n  appliesTo\n  assignments {\n    ...AssignmentFragment\n  }\n  comments\n  dealResponsibilityType {\n    ...DealResponsibilityTypeFragment\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./DealResponsibilityTypeFragment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./AssignmentFragment.graphql").definitions));


      module.exports = doc;
    
