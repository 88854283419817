import React, { lazy } from 'react'
import MainRoute from 'components/MainRoute/MainRoute'
import * as Routes from 'constants/Routes'
import * as UserPermission from 'constants/UserPermission'

const DealCreateView = lazy(() => import('./components/DealCreateView/DealCreateView'))

export default (
  <MainRoute
    path={Routes.DEAL_CREATE}
    isProtected
    component={DealCreateView}
    requiredPermission={UserPermission.EDIT_ALL}
  />
)
