import { useEffect } from 'react'
import { clearToken } from 'utils/authStorage'
import { useApolloClient } from '@apollo/react-hooks'

import { useAuth0 } from 'hooks/useAuth0'

const Logout = () => {
  const client = useApolloClient()
  const { logout } = useAuth0()

  useEffect(() => {
    clearToken()
    client.cache.reset()
    // Auth0 logout redirects for us
    logout()
  }, [])

  return null
}

export default Logout
