import React from 'react'
import {
  Field as FormikField,
  FastField as FormikFastField,
  ErrorMessage as FormikErrorMessage
} from 'formik'
import Label from '../Label/Label'
import cx from 'classnames'

import styles from './Field.scss'

const Field = ({
  name,
  label,
  hideFieldLabel,
  isOptional,
  className,
  isFast = false,
  ...props
}) => {
  if (hideFieldLabel) {
    props.label = label
  }

  const FormikFieldComponent = isFast ? FormikFastField : FormikField

  return (
    <div
      className={cx(styles.component, {
        [className]: className
      })}
    >
      <div className={styles.labelContainer}>
        {!hideFieldLabel && (
          <Label>
            {label}
          </Label>
        )}
        {isOptional && (
          <div className={styles.isOptional}>
            Optional
          </div>
        )}
      </div>
      <div className={styles.field}>
        <FormikFieldComponent
          name={name}
          {...props}
        />
      </div>
      <FormikErrorMessage
        name={name}
        render={(error) => (
          <div className={styles.error}>
            <small>{error}</small>
          </div>
        )}
      />
    </div>
  )
}

Field.displayName = 'SwitchboardField'

export default React.memo(Field)
