import React, { useEffect } from 'react'
import PageLoader from 'components/PageLoader/PageLoader'
import Header from 'components/Header/Header'
import * as Routes from 'constants/Routes'
import styles from './MainRoute.scss'
import { useAuth0 } from 'hooks/useAuth0'
import useMe from 'hooks/useMe'
import wrapRoute from 'utils/wrapRoute'
import { useHistory } from 'react-router-dom'

const MainRoute = ({
  matchProps,
  component: Component,
  isProtected,
  location,
  requiredPermission
}) => {
  const history = useHistory()

  const {
    isLoading,
    me
  } = useMe({
    onError: () => {
      history.push(Routes.LOGIN)
    }
  })

  const {
    loading: auth0Loading,
    loginWithRedirect
  } = useAuth0()

  const isLoggedIn = !!me

  // TODO: this is not triggered once auth0 comes back with an error
  useEffect(() => {
    if (isProtected && !isLoading && !auth0Loading && !isLoggedIn && loginWithRedirect) {
      if (location.pathname !== Routes.LOGIN) {
        const destination = location.pathname + location?.search || ''
        localStorage.setItem('destination', destination)
      }

      loginWithRedirect()
    }
  }, [auth0Loading, isProtected, isLoading, isLoggedIn, loginWithRedirect])

  if (isLoading || auth0Loading) {
    return (
      <PageLoader />
    )
  }

  if (isProtected && !isLoggedIn) {
    return null
  }

  if (requiredPermission) {
    const userPermissions = me?.permissions || []

    if (!userPermissions.includes(requiredPermission)) {
      history.push(Routes.HOME)
      return
    }
  }

  return (
    <>
      <Header />
      <div className={styles.content}>
        <Component {...matchProps} />
      </div>
    </>
  )
}

export default wrapRoute(MainRoute)
