import React, {
  useState,
  useRef
} from 'react'
import styles from './HeaderMenu.scss'
import {
  DownDirectionArrowIcon,
  ViewMoreNextArrowIcon
} from 'frontend-react-components'
import { Link } from 'react-router-dom'
import * as Routes from 'constants/Routes'
import { Fade } from 'components/Transitions'
import cx from 'classnames'
import useEventHandlers from 'hooks/useEventHandlers'
import useUser from 'hooks/useUser'
import {
  Button
} from 'components/FormElements'

const KEY_ESC = 27

const HeaderMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const user = useUser()
  const menuRef = useRef(null)
  const triggerRef = useRef(null)

  const handleClick = (e) => {
    if (menuRef.current.contains(e.target)) {
      return
    }

    if (triggerRef.current.contains(e.target)) {
      return
    }

    setIsOpen(false)
  }

  const handleKeyUp = e => {
    if (e.keyCode === KEY_ESC) {
      setIsOpen(false)
    }
  }

  useEventHandlers({
    mousedown: handleClick,
    keyup: handleKeyUp
  })

  const handleTriggerClick = () => {
    setIsOpen(!isOpen)
  }

  if (!user) {
    return null
  }

  return (
    <div className={styles.component}>
      <div
        ref={triggerRef}
        id='btn-header-menu-trigger'
        onClick={handleTriggerClick}
        className={cx(styles.trigger, {
          [styles.active]: isOpen
        })}
      >
        <div className={styles.triggerLabel}>
          {user.name}
        </div>
        <div className={styles.triggerIcon}>
          <DownDirectionArrowIcon />
        </div>
      </div>
      <Fade
        timeout={20}
        in={isOpen}
      >
        <div
          id='header-menu-dropdown'
          ref={menuRef}
          className={styles.menu}
        >
          <div className={styles.menuContent}>
            <div
              id='header-menu-name'
              className={styles.menuName}
            >
              {user.name}
            </div>
            <div
              id='header-menu-email'
              className={styles.menuEmail}
            >
              {user.email}
            </div>
            <div
              id='header-menu-role'
              className={styles.menuRole}
            >
              Admin
            </div>
          </div>
          <div className={styles.menuFooter}>
            <Button
              className={styles.btnLogout}
              as={Link}
              to={Routes.LOGOUT}
              id='btn-logout'
              iconAfter={(
                <ViewMoreNextArrowIcon />
              )}
              block
            >
              Log out
            </Button>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default React.memo(HeaderMenu)
