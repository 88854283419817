
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ParticipantMappingsFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ParticipantMappings"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sourceSystem"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ids"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"IdentifierFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"localizedNames"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LocalizedStringFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"participantId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"appleId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isni"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastChanged"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mostFrequestTasks"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"preferredFlag"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"repertoireOwner"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CompanyFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"spotifyUri"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":441}};
    doc.loc.source = {"body":"#import \"./IdentifierFragment.graphql\"\n#import \"./CompanyFragment.graphql\"\n#import \"./LocalizedStringFragment.graphql\"\n\nfragment ParticipantMappingsFragment on ParticipantMappings {\n  sourceSystem\n  ids {\n    ...IdentifierFragment\n  }\n  localizedNames {\n    ...LocalizedStringFragment\n  }\n  participantId\n  appleId\n  isni\n  lastChanged\n  mostFrequestTasks\n  name\n  preferredFlag\n  repertoireOwner {\n    ...CompanyFragment\n  }\n  spotifyUri\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./IdentifierFragment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./CompanyFragment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./LocalizedStringFragment.graphql").definitions));


      module.exports = doc;
    
