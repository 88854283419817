import auth0Login from './auth0Login.graphql'
import login from './login.graphql'
import getMe from './getMe.graphql'
import getDeadLetter from './getDeadLetter.graphql'
import getDeadLetters from './getDeadLetters.graphql'
import getDeals from './getDeals.graphql'
import getDeal from './getDeal.graphql'
import getDealMin from './getDealMin.graphql'
import getProjectByLocalId from './getProjectByLocalId.graphql'
import getProjectById from './getProjectById.graphql'
import getProductById from './getProductById.graphql'
import getProductByIdMin from './getProductByIdMin.graphql'
import searchDealSubjectOrchard from './searchDealSubjectOrchard.graphql'
import searchDealSubjectSony from './searchDealSubjectSony.graphql'
import searchSMEMaintenanceOwner from './searchSMEMaintenanceOwner.graphql'
import searchSMERepertoireOwner from './searchSMERepertoireOwner.graphql'
import searchSMEImprint from './searchSMEImprint.graphql'
import searchSMESAPProfitCenter from './searchSMESAPProfitCenter.graphql'
import searchSMESAPCompany from './searchSMESAPCompany.graphql'
import searchOrchardLabel from './searchOrchardLabel.graphql'
import searchOrchardSubaccount from './searchOrchardSubaccount.graphql'
import createDeal from './createDeal.graphql'
import getParticipantConflicts from './getParticipantConflicts.graphql'
import getParticipantSearch from './getParticipantSearch.graphql'
import updateParticipantMapping from './updateParticipantMapping.graphql'
import updateDeal from './updateDeal.graphql'
import getResponsibilitiesData from './getResponsibilitiesData.graphql'
import getGenreMappings from './getGenreMappings.graphql'
import updateGenreMapping from './updateGenreMapping.graphql'
import deleteGenreMapping from './deleteGenreMapping.graphql'
import createGenreMapping from './createGenreMapping.graphql'
import getTrackByIsrc from './getTrackByIsrc.graphql'
import getProductByUpc from './getProductByUpc.graphql'
import getProductByUpcMin from './getProductByUpcMin.graphql'
import injectNotification from './injectNotification.graphql'
import updatePublisherMapping from './updatePublisherMapping.graphql'
import getPublisherConflicts from './getPublisherConflicts.graphql'
import getPublisherSearch from './getPublisherSearch.graphql'
import getUsers from './getUsers.graphql'
import createUser from './createUser.graphql'
import updateUserPermissions from './updateUserPermissions.graphql'

export default {
  auth0Login,
  login,
  getMe,
  getDeadLetter,
  getDeadLetters,
  getDeals,
  getDeal,
  getDealMin,
  getProjectByLocalId,
  getProjectById,
  getProductById,
  getProductByUpc,
  getProductByUpcMin,
  getProductByIdMin,
  getParticipantConflicts,
  getParticipantSearch,
  searchDealSubjectOrchard,
  searchDealSubjectSony,
  searchSMEMaintenanceOwner,
  searchSMERepertoireOwner,
  searchSMEImprint,
  searchSMESAPProfitCenter,
  searchSMESAPCompany,
  searchOrchardLabel,
  searchOrchardSubaccount,
  createDeal,
  updateParticipantMapping,
  updateDeal,
  getResponsibilitiesData,
  getGenreMappings,
  updateGenreMapping,
  deleteGenreMapping,
  createGenreMapping,
  getTrackByIsrc,
  injectNotification,
  updatePublisherMapping,
  getPublisherConflicts,
  getPublisherSearch,
  getUsers,
  createUser,
  updateUserPermissions
}
