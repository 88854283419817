import React from 'react'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import 'react-multi-email/style.css'
import isFieldComponentEqual from 'utils/isFieldComponentEqual'

const MultiEmail = ({
  form,
  field,
  innerRef,
  id,
  ...props
}) => {
  const handleChange = value => {
    form.setFieldValue(field.name, value)
  }

  return (
    <div id={id}>
      <ReactMultiEmail
        ref={innerRef}
        name={field.name}
        emails={field.value}
        onChange={handleChange}
        valiMultiEmailEmail={email => {
          return isEmail(email) // return boolean
        }}
        {...props}
        getLabel={(
          email,
          index,
          removeEmail
        ) => {
          return (
            <div
              data-tag
              key={index}
            >
              {email}
              <span
                data-tag-handle
                onClick={() => removeEmail(index)}
              >
                ×
              </span>
            </div>
          )
        }}
      />
    </div>
  )
}

MultiEmail.displayName = 'MultiEmail'

export default React.memo(MultiEmail, isFieldComponentEqual)
