
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"GenreMappingFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"GenreMapping"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sourceSystem"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sourceGenreId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sourceGenreName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sourceSubGenreId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sourceSubGenreName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"targetSystem"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"targetGenreId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"targetGenreName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"targetSubGenreId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"targetSubGenreName"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":233}};
    doc.loc.source = {"body":"fragment GenreMappingFragment on GenreMapping {\n  id\n  sourceSystem\n  sourceGenreId\n  sourceGenreName\n  sourceSubGenreId\n  sourceSubGenreName\n  targetSystem\n  targetGenreId\n  targetGenreName\n  targetSubGenreId\n  targetSubGenreName\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
