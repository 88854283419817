import React from 'react'
import {
  Button,
  ButtonGroup
} from 'frontend-react-components'
import * as System from 'constants/System'
import styles from './SystemButtonGroup.scss'
import _ from 'lodash'
import cx from 'classnames'
import isFieldComponentEqual from '../../../../utils/isFieldComponentEqual'

const SystemButtonGroup = ({
  form,
  field,
  isDisabled
}) => {
  const hasError = _.has(form?.errors, field.name)
  const hasTouched = _.has(form?.touched, field.name)
  const value = field.value

  const handleBtnSystemClick = system => () => {
    form.setFieldValue(field.name, system)
  }

  return (
    <div
      className={cx(styles.component, {
        [styles.hasError]: hasTouched && hasError
      })}
    >
      <ButtonGroup>
        <Button
          id='system-button-sony'
          onClick={handleBtnSystemClick(System.SONY)}
          active={value === System.SONY}
          disabled={isDisabled}
        >
          Sony Music
        </Button>
        <Button
          id='system-button-orchard'
          onClick={handleBtnSystemClick(System.ORCHARD)}
          active={value === System.ORCHARD}
          disabled={isDisabled}
        >
          The Orchard
        </Button>
      </ButtonGroup>
    </div>
  )
}

SystemButtonGroup.displayName = 'SystemButtonGroup'

export default React.memo(SystemButtonGroup, isFieldComponentEqual)
