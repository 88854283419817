import React, { lazy } from 'react'
import MainRoute from 'components/MainRoute/MainRoute'
import * as Routes from 'constants/Routes'

const ProductView = lazy(() => import('./components/ProductView/ProductView'))

export default (
  <MainRoute
    path={Routes.PRODUCT}
    isProtected
    component={ProductView}
  />
)
