import React from 'react'
import cx from 'classnames'
import styles from './Input.scss'
import _ from 'lodash'
import { Form } from 'frontend-react-components'
import isFieldComponentEqual from 'utils/isFieldComponentEqual'

const Input = ({
  field,
  form,
  type = 'text',
  className,
  ...props
}) => {
  const hasTouched = _.has(form?.touched, field.name)
  const hasError = _.has(form?.errors, field.name)

  return (
    <div
      className={cx({
        [styles.hasError]: hasTouched && hasError
      })}
    >
      <div className={styles.container}>
        <Form.Control
          as='input'
          {...field}
          {...props}
          type={type}
          className={cx(className, styles.input)}
        />
      </div>
    </div>
  )
}

Input.displayName = 'Input'

export default React.memo(Input, isFieldComponentEqual)
