// Keep in sync with GraphQL DealStatus enum

const ACTIVE = 'ACTIVE'
const DRAFT = 'DRAFT'
const ARCHIVED = 'ARCHIVED'

module.exports = {
  ACTIVE,
  DRAFT,
  ARCHIVED
}
