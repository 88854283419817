import React, { lazy } from 'react'
import MainRoute from 'components/MainRoute/MainRoute'

const NotFoundPage = lazy(() => import('components/NotFoundPage/NotFoundPage'))

export default (
  <MainRoute
    exact
    isProtected
    component={NotFoundPage}
  />
)
