import React, { useEffect, useRef, useState } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'

import { useAuth0 } from 'hooks/useAuth0'
import createApolloClient from 'utils/apollo/apolloClient'

const Auth0ApolloProvider = ({ children }) => {
  const [ready, setReady] = useState(false)
  const { token, user } = useAuth0()
  const ref = useRef(token)
  const [client] = useState(createApolloClient(ref))

  useEffect(() => {
    ref.current = token
  }, [token])

  useEffect(() => {
    (async () => {
      setReady(false)
      await client.resetStore()
      setReady(true)
    })()
  }, [user])

  if (!ready) {
    return null
  }

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}

export default Auth0ApolloProvider
