import React, { useCallback } from 'react'
import { AsyncSelect } from 'components/FormElements'
import { useApolloClient } from '@apollo/react-hooks'
import isFieldComponentEqual from '../../../../utils/isFieldComponentEqual'

const SearchSelect = ({
  operation,
  optionsFormatter,
  form,
  field,
  innerRef,
  variables,
  ...props
}) => {
  const client = useApolloClient()

  const loadOptions = useCallback(async (inputValue) => {
    const res = await client.query({
      query: operation,
      variables: {
        searchText: inputValue,
        ...variables
      }
    })

    return optionsFormatter(res)
  }, [
    operation,
    variables
  ])

  return (
    <AsyncSelect
      ref={innerRef}
      hasIconSearch
      hasGroupOptions
      form={form}
      field={field}
      loadOptions={loadOptions}
      {...props}
    />
  )
}

SearchSelect.displayName = 'SearchSelect'

export default React.memo(SearchSelect, isFieldComponentEqual)
