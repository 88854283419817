import React from 'react'
import styles from './Button.scss'
import Spinner from 'components/Spinner/Spinner'
import { Button as FRCButton } from 'frontend-react-components'
import cx from 'classnames'

const Button = ({
  children,
  isLoading,
  iconBefore,
  iconAfter,
  className,
  ...props
}) => {
  const hasVariant = !!props?.variant
  const isDefault = !hasVariant

  return (
    <FRCButton
      disabled={props.disabled || isLoading || false}
      className={cx(styles.component, {
        [className]: className
      })}
      {...props}
    >
      <div className={styles.content}>
        {isLoading ? (
          <div className={styles.loader}>
            <Spinner
              primary={isDefault}
              className={`${styles.loaderSpinner} btn-loader`}
            />
          </div>
        ) : (
          <div className={styles.inner}>
            {iconBefore && (
              <div className={styles.iconBefore}>
                {iconBefore}
              </div>
            )}
            <div className={styles.label}>
              {children}
            </div>
            {iconAfter && (
              <div className={styles.iconAfter}>
                {iconAfter}
              </div>
            )}
          </div>
        )}
      </div>
    </FRCButton>
  )
}

Button.displayName = 'SwitchboardButton'

export default React.memo(Button)
