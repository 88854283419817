import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import * as Routes from 'constants/Routes'

const LoginView = lazy(() => import('./containers/LoginContainer'))

export default (
  <Route
    exact
    path={Routes.LOGIN_OLD}
    component={LoginView}
  />
)
