import React from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styles from 'styles/toast.scss'
import ToastCloseIcon from 'components/ToastCloseIcon/ToastCloseIcon'

toast.configure({
  autoClose: 8000,
  draggable: false,
  hideProgressBar: true,
  position: 'bottom-right',
  toastClassName: styles.component,
  closeButton: <ToastCloseIcon />
})
