import React, { lazy } from 'react'
import MainRoute from 'components/MainRoute/MainRoute'
import * as Routes from 'constants/Routes'

const DealView = lazy(() => import('./components/DealView/DealView'))

export default (
  <MainRoute
    path={Routes.DEAL}
    isProtected
    component={DealView}
  />
)
