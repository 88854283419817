export const HOME = '/'

export const DEAL = '/deals/:dealId'
export const DEAL_LIST = '/deals'
export const DEAL_CREATE = '/deals-create'
export const DEAL_CREATE_DETAILS = `${DEAL_CREATE}/details`
export const DEAL_CREATE_RESPONSIBILITIES = `${DEAL_CREATE}/responsibilities`
export const DEAL_CREATE_SUMMARY = `${DEAL_CREATE}/summary`

export const PROJECT = '/project/:system/:localId'
export const PRODUCT = '/product/:system/:upc'
export const TRACK = '/track/:system/:isrc'

export const DATA_MATCHING = '/data-matching'
export const DATA_MATCHING_PARTICIPANTS = `${DATA_MATCHING}/participants`
export const DATA_MATCHING_PUBLISHERS = `${DATA_MATCHING}/publishers`

export const ADMIN = '/admin'
export const ADMIN_USER_LIST = `${ADMIN}/users`
export const ADMIN_STATUS = `${ADMIN}/status`
export const ADMIN_STATUS_DEAD_LETTER = `${ADMIN_STATUS}/dead-letter/:messageId`

export const AUTH0_RECEIVER = '/auth0'
export const LOGIN = '/login'
export const LOGIN_OLD = '/login-old'
export const LOGOUT = '/logout'
export const FORGOTTEN_PASSWORD = '/forgotten-password'
export const UNKNOWN_USER = '/not-permitted'

export const NOT_FOUND = '/not-found'
export const REFRESH = '/refresh'
